@mixin button-fill($color) {
  background-color: $color;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: darken($color, 4%);
    }

    &:active {
      background-color: lighten($color, 4%);
    }
  }
}


.btn {
  $bg_primary: #00965e;
  $bg_secondary: #909aa2;
  $bg_active: #00965e;
  $bg_warning: #dc4f53;

  cursor: pointer;
  text-align: center;
  border: none;
  font-size: 16px;
  text-wrap: nowrap;

  &:disabled {
    opacity: .65;
    cursor: not-allowed;
  }

  &__primary,
  &__secondary,
  &__active,
  &__warning {
    height: 45px;
    text-align: center;
    border-radius: 4px;
    padding: 12px 20px 14px;
    line-height: 1;
    color: #F7F7F7;

    &.hours {
      max-width: 150px;
    }
  }

  &__primary {
    @include button-fill($bg_primary);
  }

  &__secondary {
    @include button-fill($bg_secondary);
  }

  &__active {
    @include button-fill($bg_active);
  }

  &__warning {
    @include button-fill($bg_warning);
  }

  &__link {
    position: relative;
    background: transparent;
    color: #939fa6;
    padding: 0;
    border-bottom: 1px solid #939fa6;

    &:not(:disabled) {
      &:hover,
      &:focus {
        color: darken(#939fa6, 12%);
      }

      &:active {
        color: lighten(#939fa6, 12%);
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__block {
    width: 100%;
  }
}
