/* You can add global styles to this file, and also import other style files */
//@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "styles/modal.scss";
@import "styles/button.scss";
@font-face {
  font-family: 'TitleSans';
  src: url('fonts/BNPPTitleSans.otf') format("opentype");
}

@font-face {
  font-family: 'BNPP Sans';
  font-weight: 400;
  src: url('./fonts/BNPPSans.otf') format('woff');
  src: url('./fonts/BNPPSans.ttf') format('truetype');
}

@font-face {
  font-family: 'BNPP Sans';
  font-weight: 700;
  src: url('./fonts/BNPPSansBold.otf') format('opentype');
  src: url("./fonts/BNPPSansBold.ttf") format('truetype');
}

* {
  box-sizing: border-box;
}

html, body { height: 100%; }
body { margin: 0; font-family: BNPP Sans,  sans-serif !important;}
h1, h2, h3 { font-family: TitleSans, sans-serif !important; }
