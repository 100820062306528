.modal {
  --mdc-filled-text-field-caret-color: #00965e;
  --mdc-filled-text-field-focus-active-indicator-color: #00965e;
  --mdc-filled-text-field-focus-label-text-color: #00965e;
  --mdc-outlined-text-field-caret-color: #00965e;
  --mdc-outlined-text-field-focus-outline-color: #00965e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 150, 94, 0.87);
  --mat-form-field-focus-select-arrow-color: #00965e;

  max-width: 650px;
  position: relative;
  padding: 2rem;

  .modal__header {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;

    .close {
      cursor: pointer;
      position: relative;
      display: block;
      width: 26px;
      height: 26px;
      overflow: hidden;
    }

    .close:hover {
      opacity: .5;
    }

    .close:before, .close:after {
      position: absolute;
      left: 13px;
      content: " ";
      height: 26px;
      width: 1px;
      background-color: #2C3E50;
    }

    .close:before {
      transform: rotate(45deg);
    }

    .close:after {
      transform: rotate(-45deg);
    }
  }

  &__title {
    text-align: center;
    font-size: 26px;
    line-height: 1;
    color: #939fa6;
    margin: 0;
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.info {
      max-width: 560px;
      margin: 40px auto 0;
    }

    .form__field {
      margin-bottom: 1.5rem;
    }

    .mat-input-element {
      background: #ffffff;
      font-size: 18px;
      line-height: 21px;
      color: #939fa6;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
      background-color: #E3E3E3;
    }
  }
  .mat-mdc-form-field-bottom-align {
    display: none;
  }
}

.mat-dialog-container {
  border-radius: 0 !important;
}

.success.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background: #00965e;
  }
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #00965e;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #00965e;
  --mat-outlined-button-ripple-color: rgba(103, 58, 183, 0.1);
}
